<template>
  <div class="member-layout">

    <div class="memberP-page-title">아이디 찾기 / 비밀번호 변경</div>

    <div class="input-title">아이디 찾기<span class="color-1">*</span></div>
    <div class="input-title-desc">본인인증 받으신 휴대폰번호를 입력해주세요.<br> SMS로 아이디 (이메일 주소)를 보내드립니다.</div>
    <input-field v-model="phone" numberOnly placeholder="휴대폰번호" :errorMessage="phoneMsg" maxLength="11"/>
    <div class="mt-5">
      <button class="btn btn-x-large color-1 btn-block" @click="findId">SMS로 아이디 전송</button>
    </div>

    <div class="horizon-bar w-100p mt-50 mb-40"></div>

    <div class="input-title">비밀번호 변경<span class="color-1">*</span></div>
    <div class="input-title-desc">가입 시 등록한 이메일(아이디) 주소를 입력해주세요.<br>비밀번호 변경 링크를 이메일 주소로 보내드립니다.</div>
    <input-field v-model="email" placeholder="이메일(아이디)" :errorMessage="emailMsg" maxLength="100"/>
    <div class="mt-5" @click="findPw">
      <button class="btn btn-x-large color-1 btn-block">비밀번호 변경 URL 전송</button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
export default {
  name: "find",
  data: function () {
    return {
      phone: '',
      phoneMsg: null,
      email: '',
      emailMsg: null,
    }
  },
  watch: {
    phone() {
      this.phoneMsg = null
    },
    email() {
      this.emailMsg = null
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    validatePhone() {
      if (!this.phone) {
        this.phoneMsg = this.$msg('required.input')
        return false
      }
      if (!this.phone.patternCheck('phone')) {
        this.phoneMsg = this.$msg('phone.check')
        return false
      }
      return true
    },
    async findId() {
      if (this.validatePhone()) {
        const { result } = await this.$api.findId({ phone: this.phone })
        switch (result) {
          case 'success':
            this.phone = null
            this.alertPopup(this.$msg('phone.send.email'))
            break;
          case 'fail':
            this.alertPopup(this.$msg('phone.send.fail'))
            break;
          case 'no-exist':
            this.alertPopup(this.$msg('phone.no-exist'))
            break;
          case 'kakao-existed':
            this.alertPopup(this.$msg('kakao-existed'), true)
            break;
          default:
            this.alertPopup(this.$msg('phone.send.fail'))
        }
      }
    },
    validateEmail() {
      if (!this.email) {
        this.emailMsg = this.$msg('required.input')
        return false
      }
      if (!this.email.patternCheck('email')) {
        this.emailMsg = this.$msg('email.check')
        return false
      }
      return true
    },
    async findPw() {
      if (this.validateEmail()) {
        const { result } = await this.$api.findPw({ email: this.email })
        switch (result) {
          case 'success':
            this.email = null
            this.alertPopup(this.$msg('email.send'))
            break;
          case 'no-exist':
            this.alertPopup(this.$msg('email.no-exist'))
            break;
          case 'kakao-existed':
            this.alertPopup(this.$msg('kakao-existed'), true)
            break;
          case 'email-leave':
            this.alertPopup(this.$msg('email.leave'))
            break;
          default:
        }
      }
    },
    alertPopup(msg, isGoLogin=false) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'OK',
        okayCallback: () => {
          this.setMsgPopup()
          if (isGoLogin) this.$router.push('/login')
        }
      })
    },
  },
}
</script>
